









import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import Page from '@/components/Page.vue'

@Component<AboutView>({
  components: {
    Header,
    Page
  }
})
export default class AboutView extends Vue {}
